body {
  font-family: Arial, sans-serif;
}
.career-container {
    padding: 1px ; 
    
  }
  
  .container {
    max-width: 12000px;
    margin: 20px 100px;
  }
  
  .career-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative; 
  }
  
  .career-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: -9999px; 
    right: -9999px; 
    width: calc(100% + 9999px * 2); 
    height: 100%;
    background-color: #e0e3e9;
    z-index: -1; 
  }
  .career-title {
    font-size: 14px;
    margin: 0;
    text-align: left;
  }
  
  .breadcrumb {
    font-size: 18px;
    text-align: left;
  }
  
  .breadcrumb-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .breadcrumb-link:hover {
    text-decoration: underline;
  }
  
  .career-description {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: left;
  }
  
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
  }
  button:hover {
    background-color: #0056b3;
  }
  textarea {
    height: 100px; 
  }
  input, textarea {
    width: 10px; 
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .button-container {
    display: flex;
    margin-top: 10px;  
    margin-left: 460px;
    width: 110px; 
  }
  
  