/* Footer Styles */

.footer {
    background: linear-gradient(135deg, #1a1a1a, #2c2c2c);
    color: #ffffff;
    padding: 60px 0;
    position: relative;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 120%;
    height: 120%;
    background: rgba(255, 255, 255, 0.03);
    transform: translateX(-50%) rotate(45deg);
    z-index: 0;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    z-index: 1;
}

.footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.footer-col {
    flex: 1;
    min-width: 280px;
    margin: 20px 0;
    padding: 0 10px;
}

.footer-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    color: #f39c12;
    text-transform: uppercase;
    border-bottom: 2px solid #444;
    padding-bottom: 10px;
}

.contact-details {
    list-style: none;
    padding: 0;
    font-size: 16px;
    line-height: 1.8;
}

.contact-details li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #ffffff;
}

.contact-icon {
    margin-right: 12px;
    color: #f39c12;
    font-size: 18px;
}

.footer-links {
    list-style: none;
    padding: 0;
    font-size: 16px;
    line-height: 1.8;
}

.footer-links li {
    margin-bottom: 12px;
}

.footer-links a {
    text-decoration: none;
    color: #ffffff;
    transition: color 0.3s ease;
    font-weight: 500;
}

.footer-links a:hover {
    color: #f39c12;
    text-decoration: underline;
}

.read-more-button {
    display: inline-block;
    margin-top: 15px;
    padding: 12px 25px;
    color: #ffffff;
    background: #f39c12;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    transition: background 0.3s ease, transform 0.3s ease;
}

.read-more-button:hover {
    background: #e67e22;
    transform: scale(1.05);
}

.footer-bottom {
    background: #0d0d0d;
    color: #ffffff;
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    margin-top: 30px;
    border-top: 1px solid #444;
}

.footer-bottom a {
    color: #f39c12;
    text-decoration: none;
    margin: 0 5px;
    font-weight: 500;
}

.footer-bottom a:hover {
    text-decoration: underline;
    color: #e67e22;
}

@media (max-width: 768px) {
    .footer-row {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    .footer-col {
        text-align: center;
    }
    .contact-details,
    .footer-links {
        text-align: center;
    }
    .read-more-button {
        margin: 0 auto;
    }
}