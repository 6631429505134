.testimonials-section {
    padding: 50px 20px;
    background: #f9f9f9; /* Replace with your desired background color */
    text-align: center;
    color: #333;
  }
  
  .testimonials-title {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .testimonial {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: 0 10px;
  }
  
  .testimonial-text {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #666;
  }
  