/* General Navigation Styling */

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #111010;
    position: relative;
    padding: 0 50px;
    height: 80px;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid #e0e0e0;
    font-family: 'Arial', sans-serif;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}


/* Logo Section */

nav .title {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo_content {
    display: flex;
    align-items: center;
}

.logo_img img {
    width: 120px;
    height: auto;
}


/* Navigation Links */

nav ul {
    display: flex;
    gap: 20px;
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    text-decoration: none;
    color: #333333;
    font-weight: 500;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

nav ul li a:hover:not(.active),
nav ul li a.active {
    background-color: #a5b3e0;
    color: #ffffff;
}


/* Mobile Menu (Hamburger) */

nav .menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

nav .menu span {
    display: block;
    height: 4px;
    width: 100%;
    background-color: #333333;
    border-radius: 2px;
    transition: all 0.3s ease;
}

nav .menu.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

nav .menu.active span:nth-child(2) {
    opacity: 0;
}

nav .menu.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}


/* Mobile View */

@media (max-width: 768px) {
    nav {
        padding: 10px 20px;
        height: auto;
    }
    nav .menu {
        display: flex;
        position: relative;
        z-index: 11;
    }
    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        background-color: #ffffff;
        position: absolute;
        top: 80px;
        left: 0;
        padding: 10px 0;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
    nav ul.open {
        display: flex;
    }
    nav ul li {
        width: 100%;
        text-align: center;
    }
    nav ul li a {
        padding: 10px 0;
        width: 100%;
    }
}


/* Enhancements for Hover & Focus */

nav ul li a:focus,
nav ul li a:hover {
    outline: none;
    background-color: #7f9ae3;
    color: #ffffff;
}


/* Transition Effects */

nav,
nav ul li a {
    transition: all 0.3s ease;
}