.home-container {
    font-family: Arial, sans-serif;
    text-align: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 40px;
    margin-right: 10px;
}

.navbar a {
    margin: 0 15px;
    text-decoration: none;
    color: #333;
}

.main-content {
    padding: 50px 20px;
}

.main-content h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.main-content p {
    font-size: 1.2em;
    line-height: 1.5;
}

.read-more {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: orange;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}