/* General Styling */

.our-services {
    background-color: #f9f9f9;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}

.our-services-container {
    max-width: 1200px;
    margin: 0 auto;
}

.our-services-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
}

.our-services-description {
    font-size: 1.2rem;
    color: #666666;
    margin-bottom: 40px;
    line-height: 1.5;
}


/* Service Cards */

.service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.service-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 280px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.service-card-icon img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
}

.service-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
}

.service-card-description {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
    line-height: 1.6;
}


/* Buttons */

.service-card-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background: #007bff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

.service-card-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}


/* Responsive Design */

@media (max-width: 768px) {
    .service-cards {
        flex-direction: column;
        align-items: center;
    }
    .our-services-title {
        font-size: 2rem;
    }
    .our-services-description {
        font-size: 1rem;
    }
    .service-card {
        width: 90%;
    }
}


/* General Styling */

.services-container {
    background-color: #f9f9f9;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* Header Section */

.services-header {
    margin-bottom: 20px;
    text-align: center;
}

.services-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333333;
}

.breadcrumb {
    font-size: 1rem;
    color: #666666;
    margin-top: 10px;
}

.breadcrumb-link {
    text-decoration: none;
    color: #007bff;
}

.breadcrumb-link:hover {
    text-decoration: underline;
}


/* Description Section */

.services-description {
    font-size: 1rem;
    color: #444444;
    line-height: 1.6;
    margin-bottom: 30px;
    text-align: justify;
}


/* Focus Section */

.focus-section {
    margin-top: 30px;
}

.focus-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
    text-align: center;
}

.focus-list {
    list-style: disc inside;
    font-size: 1rem;
    color: #555555;
    margin-bottom: 20px;
    padding-left: 20px;
}


/* Priority Section */

.priority-section {
    margin-top: 30px;
}

.service-priority {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
    text-align: center;
}

.priority-list {
    list-style: circle inside;
    font-size: 1rem;
    color: #555555;
    margin-bottom: 20px;
    padding-left: 20px;
}

.priority-list li {
    margin-bottom: 10px;
}


/* Conclusion */

.services-conclusion {
    font-size: 1rem;
    color: #444444;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}


/* Responsive Design */

@media (max-width: 768px) {
    .container {
        padding: 15px;
    }
    .services-title {
        font-size: 2rem;
    }
    .services-description,
    .focus-list,
    .priority-list {
        font-size: 0.9rem;
    }
}