/* General Container Styling */

.about-us-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    padding: 40px 20px;
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


/* Header Section */

.about-us-header {
    text-align: center;
    margin-bottom: 20px;
}

.about-us-title {
    font-size: 36px;
    font-weight: bold;
    color: #007bff;
}

.breadcrumb {
    font-size: 14px;
    color: #777;
}

.breadcrumb-link {
    color: #007bff;
    text-decoration: none;
}

.breadcrumb-link:hover {
    text-decoration: underline;
}


/* Content Section */

.about-us-content {
    text-align: justify;
    margin-bottom: 30px;
}

.about-us-description {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 15px;
}

.about-us-description.highlight {
    font-weight: bold;
    color: #007bff;
}


/* Strengths Section */

.our-strengths-section {
    margin-top: 20px;
}

.our-strengths-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.strengths-list {
    list-style: none;
    padding: 0;
}

.strengths-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 16px;
}

.strengths-list li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #007bff;
    font-weight: bold;
    font-size: 18px;
}