/* Slider Container */

.slider-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


/* Slider Item */

.slider-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
}


/* Caption Overlay */

.slider-caption {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}


/* Custom Arrows */

.custom-arrow {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    color: #fff;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: background 0.3s;
    width: 50px;
    height: 50px;
}

.custom-arrow:hover {
    background: rgba(0, 0, 0, 0.9);
}

.custom-prev {
    left: 15px;
}

.custom-next {
    right: 15px;
}


/* Dots Styling */

.slick-dots {
    bottom: 15px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: #fff;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #007bff;
}