/* General Styles */

.contact-container {
    background-color: #f8f9fa;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* Header Section */

.contact-header {
    margin-bottom: 30px;
    text-align: center;
}

.contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333333;
}

.breadcrumb {
    font-size: 1rem;
    color: #666666;
    margin-top: 10px;
}

.breadcrumb-link {
    text-decoration: none;
    color: #007bff;
}

.breadcrumb-link:hover {
    text-decoration: underline;
}


/* Content Section */

.contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.contact-info {
    flex: 1;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.contact-info h3 {
    font-size: 1.8rem;
    color: #007bff;
    margin-bottom: 10px;
}

.contact-info p {
    font-size: 1rem;
    color: #555555;
    margin-bottom: 10px;
}

.contact-form {
    flex: 2;
}

.contact-form p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    font-size: 1rem;
    color: #333333;
    margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form textarea {
    resize: none;
    height: 100px;
}

.submit-button {
    align-self: flex-start;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}


/* Response Message */

.response-message {
    font-size: 1rem;
    color: #007bff;
    margin-top: 10px;
}


/* Responsive Design */

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
    }
    .contact-info,
    .contact-form {
        width: 100%;
    }
}